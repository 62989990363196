.labkickIcon {
  width: 0.875rem;
  height: 1.313rem;
  position: relative;
  object-fit: cover;
}

.fAQIcons {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-7xs) 0 0;
}

.answer {
  flex: 1;
  position: relative;
  line-height: 1.563rem;
  display: inline-block;
  min-width: 15.688rem;
  max-width: 100%;
}

.fAQContent,
.question {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-7xl);
  max-width: 100%;
  font-size: var(--p1-size);
  font-family: var(--p2);
}

.fAQContent {
  display: flex;
  flex-direction: row;
}

.question {
  margin: 0 !important;
  position: relative;
  flex-direction: column;
  gap: var(--gap-3xl);
  text-align: left;
  font-size: var(--h4-size);
  color: var(--black);
  font-family: var(--h2);
  /* width: 40%;
  min-width: 300px; */
  width: 400px;
  max-width: 100%;
}

.answer {
  font-weight: bold;
  width: auto;
  flex: 1;
}

.fAQIcons {
  width: fit-content;
  display: flex;
}

@media screen and (max-width: 1100px) {
  .fAQContent {
    display: inline-flex;
  }

  .question {
    width: calc(100% - 40px);
    margin: 0 auto;
  }

  .fAQIcons {
    margin-right: 10px;
    display: flex;
  }
}

@media screen and (max-width: 450px) {
  .whatTypesOf {
    font-size: var(--font-size-lgi);
  }

  .fAQContent {
    gap: 10px;
  }

  .answer {
    min-width: unset;
  }
}