.container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: var(--h2);
}

.title {
  font-size: var(--h3-size);
  margin-bottom: 20px;
  color: var(--black);
}

.metaContainer {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  /* Changed from flex-start to flex-end */
  margin-bottom: 10px;
  /* Reduced from 20px to 10px */
}

.leftContent {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.authorInfo {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.authorName {
  font-size: var(--p2-size);
  color: var(--black);
  margin-right: 10px;
}

.date {
  font-size: var(--p3-size);
  color: var(--color-gray-100);
}

.tags {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 0;
  /* Changed from 10px to 0 */
}

.tag {
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 20px;
  padding: 2px 8px;
  font-size: 0.75rem;
  margin-right: 6px;
  margin-bottom: 6px;
  text-transform: uppercase;
}

.openButton {
  display: flex;
  align-items: center;
  background-color: white;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  padding: 8px 16px;
  font-size: var(--p2-size);
  cursor: pointer;
  transition: background-color 0.3s;
  white-space: nowrap;
  align-self: flex-end;
  /* Added to align button to the bottom */
}

.buttonLogo {
  width: 20px;
  height: 20px;
  margin-right: 8px;
}

.divider {
  border: none;
  border-top: 1px solid #e0e0e0;
  margin: 10px 0;
  /* Changed from 20px 0 to 10px 0 */
}

.tag[data-type="CIRCULAR"] {
  color: #FF8C00;
  background-color: rgba(255, 140, 0, 0.1);
}

.tag[data-type="GIBSON"] {
  color: #E91E63;
  background-color: rgba(233, 30, 99, 0.1);
}

.tag[data-type="PLASMID_MAP"] {
  color: #4CAF50;
  background-color: rgba(76, 175, 80, 0.1);
}

.tag[data-type="GOLDEN_GATE"] {
  color: #2196F3;
  background-color: rgba(33, 150, 243, 0.1);
}

.tag[data-type="COMBINATORIAL"] {
  color: #9C27B0;
  background-color: rgba(156, 39, 176, 0.1);
}

.tag[data-type="LINEAR"] {
  color: #FF5722;
  background-color: rgba(255, 87, 34, 0.1);
}

.tag[data-type="POOLED"] {
  color: #795548;
  background-color: rgba(121, 85, 72, 0.1);
}

.tag[data-type="LIBRARY"] {
  color: #607D8B;
  background-color: rgba(96, 125, 139, 0.1);
}

.tag[data-type="TRADITIONAL"] {
  color: #FF9800;
  background-color: rgba(255, 152, 0, 0.1);
}

.tag[data-type="RESTRICTION_CHECK"] {
  color: #009688;
  background-color: rgba(0, 150, 136, 0.1);
}

.tag[data-type="CRISPR"] {
  color: #3F51B5;
  background-color: rgba(63, 81, 181, 0.1);
}

.tag[data-type="PRIMER_DESIGN"] {
  color: #CDDC39;
  background-color: rgba(205, 220, 57, 0.1);
}

.tag[data-type="SEAMLESS_CLONING"] {
  color: #FFC107;
  background-color: rgba(255, 193, 7, 0.1);
}

.tag[data-type="RESTRICTION_ANALYSIS"] {
  color: #00BCD4;
  background-color: rgba(0, 188, 212, 0.1);
}

.tag[data-type="VECTOR_SELECTION"] {
  color: #8BC34A;
  background-color: rgba(139, 195, 74, 0.1);
}

.tag[data-type="TAG_LIBRARY"] {
  color: #673AB7;
  background-color: rgba(103, 58, 183, 0.1);
}

.tag[data-type="PROJECT_MANAGEMENT"] {
  color: #FF4081;
  background-color: rgba(255, 64, 129, 0.1);
}

.tag[data-type="GANTT_CHART"] {
  color: #03A9F4;
  background-color: rgba(3, 169, 244, 0.1);
}

.tag[data-type="BILL_OF_MATERIALS"] {
  color: #FF5252;
  background-color: rgba(255, 82, 82, 0.1);
}

.tag[data-type="HIGH_THROUGHPUT"] {
  color: #7C4DFF;
  background-color: rgba(124, 77, 255, 0.1);
}

.tag[data-type="DNA_REUSE"] {
  color: #FF6E40;
  background-color: rgba(255, 110, 64, 0.1);
}

.tag[data-type="AI"] {
  color: #1DE9B6;
  background-color: rgba(29, 233, 182, 0.1);
}

.tag[data-type="OLIGO_TILING"] {
  color: #F50057;
  background-color: rgba(245, 0, 87, 0.1);
}

.tag[data-type="TUTORIAL"] {
  color: #F50057;
  background-color: rgba(245, 0, 87, 0.1);
}

.tag[data-type="DNA_SYNTHESIS"] {
  color: #76FF03;
  background-color: rgba(118, 255, 3, 0.1);
}

.tag[data-type="ECHO_WORKLISTS"] {
  color: #FF3D00;
  background-color: rgba(255, 61, 0, 0.1);
}

.tag[data-type="AUTOMATION"] {
  color: #00E5FF;
  background-color: rgba(0, 229, 255, 0.1);
}

.tag[data-type="AUTOMATED_PLANNING"] {
  color: #C51162;
  background-color: rgba(197, 17, 98, 0.1);
}

.tag[data-type="TARGET_SEQUENCE"] {
  color: #64DD17;
  background-color: rgba(100, 221, 23, 0.1);
}

.tag[data-type="MACHINE_LEARNING"] {
  color: #AA00FF;
  background-color: rgba(170, 0, 255, 0.1);
}

.tag[data-type="STRATEGY_OPTIMIZATION"] {
  color: #FFD600;
  background-color: rgba(255, 214, 0, 0.1);
}

.tag[data-type="ROBOTICS"] {
  color: #00B8D4;
  background-color: rgba(0, 184, 212, 0.1);
}

.tag[data-type="WORKFLOW_GENERATION"] {
  color: #AEEA00;
  background-color: rgba(174, 234, 0, 0.1);
}

.tag[data-type="EXPRESSION_TESTING"] {
  color: #00B8D4;
  background-color: rgba(0, 184, 212, 0.1);
}


.content {
  font-size: var(--p1-size);
  line-height: 1.6;
  color: var(--black);
}

/* Add this new rule */
.content img {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 20px auto;
  cursor: pointer;
  /* Add this line to show it's clickable */
}

.metaContainer {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  /* Changed from flex-start to flex-end */
  margin-bottom: 10px;
  /* Reduced from 20px to 10px */
}

/* Add these new styles */
.fullscreenOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.fullscreenImage {
  max-width: 90%;
  max-height: 90%;
  object-fit: contain;
}

.closeButton {
  position: absolute;
  top: 20px;
  right: 20px;
  color: white;
  font-size: 30px;
  cursor: pointer;
}

.downloadButton {
  display: inline-block;
  background-color: var(--primary-color);
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: var(--p2-size);
  text-decoration: none;
  margin-top: 20px;
  transition: background-color 0.3s ease;
}

.downloadButton:hover {
  background-color: var(--primary-color-dark);
}