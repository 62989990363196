.seeWhatOthers {
  margin: 0 !important;
  height: 5.5rem;
  width: 27.688rem;
  position: absolute;
  top: 5.906rem;
  left: 4.5rem;
  font-size: inherit;
  font-weight: 700;
  font-family: inherit;
  display: inline-block;
}
.benefitImage,
.testimonials {
  display: flex;
  flex-direction: row;
  max-width: 100%;
}
.testimonials {
  height: 40.125rem;
  width: 69.75rem;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
  align-content: center;
  position: relative;
  gap: 5.5rem 4.875rem;
}
.benefitImage {
  width: 87.75rem;
  align-items: flex-start;
  justify-content: center;
  padding: 0 var(--padding-xl) var(--padding-6xs);
  box-sizing: border-box;
  text-align: left;
  font-size: var(--h2-size);
  color: var(--black);
  font-family: var(--h2);
}
@media screen and (max-width: 825px) {
  .seeWhatOthers {
    font-size: var(--font-size-16xl);
  }
  .testimonials {
    height: auto;
    min-height: 642;
  }
}
@media screen and (max-width: 450px) {
  .seeWhatOthers {
    font-size: var(--font-size-7xl);
  }
}
