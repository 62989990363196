@import url("https://fonts.googleapis.com/css2?family=Proxima+Nova:wght@400;600;700&display=swap&format=woff2");

@font-face {
  font-family: "Shree Devanagari 714";
  src: url("/public/ShreeDev0714-01.ttf");
  font-weight: 400;
}

@font-face {
  font-family: "Shree Devanagari 714";
  src: url("/public/ShreeDev0714-Bold-02.ttf");
  font-weight: 700;
}

body {
  margin: 0;
  line-height: normal;
}

:root {
  /* fonts */
  --h2: "Proxima Nova", sans-serif;
  --p2: "Shree Devanagari 714";

  /* font sizes */
  --h4-size: 1.5rem;
  --p2-size: 0.938rem;
  --p3-size: 0.75rem;
  --p4-size: 0.6rem; /* New smaller font size for date and tags */
  --h2-size: 2.75rem;
  --font-size-7xl: 1.625rem;
  --font-size-16xl: 2.188rem;
  --font-size-lgi: 1.188rem;
  --p1-size: 1.125rem;
  --h3-size: 2rem;
  --h1-size: 3.75rem;
  --font-size-17xl: 2.25rem;
  --font-size-29xl: 3rem;

  /* Colors */
  --color-gainsboro-100: #e6e6e6;
  --color-gainsboro-200: rgba(230, 230, 230, 0.09);
  --color: #fff;
  --gradient-full: #ff8c00;
  --color-gray-100: #787878;
  --color-gray-200: rgba(0, 0, 0, 0.65);
  --black: #000;
  --pink: #eb26f1;
  --color-whitesmoke: #f7f7f7;
  --color-darkorchid: #d93dea;
  --color-darkgray: #aaa;
  --color-chocolate: #cc5900;
  --color-silver: rgba(196, 196, 196, 0);

  /* Gaps */
  --gap-xl: 1.25rem;
  --gap-2xl: 1.312rem;
  --gap-69xl: 5.5rem;
  --gap-22xl: 2.562rem;
  --gap-11xl: 1.875rem;
  --gap-lg: 1.125rem;
  --gap-mini: 0.937rem;
  --gap-46xl: 4.062rem;
  --gap-13xl: 2rem;
  --gap-base: 1rem;
  --gap-30xl: 3.062rem;
  --gap-6xl: 1.562rem;
  --gap-mid: 1.062rem;
  --gap-7xl: 1.625rem;
  --gap-4xl: 1.437rem;
  --gap-3xl: 1.375rem;
  --gap-18xl: 2.312rem;
  --gap-10xs: 0.187rem;
  --gap-11xs: 0.125rem;
  --gap-62xl: 5.062rem;
  --gap-21xl: 2.5rem;
  --gap-182xl: 12.562rem;
  --gap-81xl: 6.25rem;
  --gap-31xl: 3.125rem;
  --gap-xl-6: 1.287rem;
  --gap-102xl: 7.562rem;
  --gap-17xl: 2.25rem;

  /* Paddings */
  --padding-6xs: 0.437rem;
  --padding-154xl: 10.812rem;
  --padding-xl: 1.25rem;
  --padding-53xl: 4.5rem;
  --padding-369xl: 24.25rem;
  --padding-175xl: 12.125rem;
  --padding-17xl: 2.25rem;
  --padding-31xl: 3.125rem;
  --padding-8xl: 1.687rem;
  --padding-20xl: 2.437rem;
  --padding-19xl: 2.375rem;
  --padding-7xl: 1.625rem;
  --padding-12xs: 0.062rem;
  --padding-10xl: 1.812rem;
  --padding-23xl: 2.625rem;
  --padding-65xl: 5.25rem;
  --padding-2xl: 1.312rem;
  --padding-36xl: 3.437rem;
  --padding-44xl: 3.937rem;
  --padding-41xl: 3.75rem;
  --padding-9xs: 0.25rem;
  --padding-7xs: 0.375rem;
  --padding-8xs: 0.312rem;
  --padding-102xl: 7.562rem;
  --padding-101xl: 7.5rem;
  --padding-11xl: 1.875rem;
  --padding-22xl: 2.562rem;
  --padding-80xl: 6.187rem;
  --padding-13xl: 2rem;
  --padding-5xs: 0.5rem;
  --padding-2xs: 0.687rem;
  --padding-base: 1rem;
  --padding-9xl: 1.75rem;
  --padding-86xl: 6.562rem;
  --padding-124xl: 8.937rem;
  --padding-33xl: 3.25rem;
  --padding-74xl: 5.812rem;
  --padding-12xl: 1.937rem;
  --padding-5xl: 1.5rem;
  --padding-91xl: 6.875rem;
  --padding-15xl: 2.125rem;
  --padding-239xl: 16.125rem;
  --padding-110xl: 8.062rem;
  --padding-73xl: 5.75rem;
  --padding-6xl: 1.562rem;
  --padding-290xl: 19.312rem;
  --padding-135xl: 9.625rem;
  --padding-58xl: 4.812rem;
  --padding-16xl: 2.187rem;
  --padding-183xl: 12.625rem;
  --padding-935xl: 59.625rem;
  --padding-10xl-6: 1.85rem;
  --padding-12xs-1: 0.006rem;
  --padding-8xl-6: 1.725rem;
  --padding-7xl-6: 1.662rem;
  --padding-7xl-5: 1.656rem;
  --padding-449xl: 29.25rem;
  --padding-11xs: 0.125rem;
  --padding-241xl: 16.25rem;
  --padding-133xl: 9.5rem;
  --padding-smi: 0.812rem;
  --padding-lg: 1.125rem;

  /* Border radiuses */
  --br-12xl: 31px;
  --br-47xl: 66px;
  --br-xl: 20px;
  --br-2xl: 21px;
}