.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    font-family: var(--h2);
}

.title {
    font-size: var(--h2-size);
    margin-bottom: 20px;
    color: var(--black);
}

.grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
}

.card {
    background: linear-gradient(135deg, var(--gradient-start), var(--gradient-end));
    border-radius: var(--br-xl);
    padding: 20px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    height: auto;
    min-height: 180px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    position: relative;
    overflow: hidden;
    cursor: pointer;
}

.card::before {
    content: none;
    /* Remove the previous gradient overlay */
}

.card:hover {
    transform: translateY(-5px);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

.cardTitle {
    font-size: 1.25rem;
    /* Reduced from 1.5rem */
    font-weight: 600;
    margin-bottom: 15px;
    color: var(--black);
    line-height: 1.2;
    min-height: 2.4em;
    /* Ensures at least two lines */
    display: -webkit-box;
    /* -webkit-line-clamp: 2; */
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.authorInfo {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.avatar {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 10px;
}

.authorName {
    font-size: var(--p2-size);
    color: var(--black);
}

.date {
    font-size: var(--p3-size);
    color: var(--color-gray-100);
    margin-bottom: 0;
    /* Remove bottom margin */
}

.tags {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
}

.tag {
    background-color: rgba(255, 255, 255, 0.7);
    border-radius: 20px;
    padding: 2px 8px;
    font-size: var(--p4-size);
    margin-left: 6px;
    text-transform: uppercase;
    line-height: 1.5;
    display: inline-flex;
    align-items: center;
}

.tag[data-type="CIRCULAR"] {
    color: #FF8C00;
}

.tag[data-type="GIBSON"] {
    color: #E91E63;
}

.tag[data-type="PLASMID_MAP"] {
    color: #4CAF50;
}

.tag[data-type="GOLDEN_GATE"] {
    color: #2196F3;
}

.tag[data-type="COMBINATORIAL"] {
    color: #9C27B0;
}

.tag[data-type="LINEAR"] {
    color: #FF5722;
}

.tag[data-type="POOLED"] {
    color: #795548;
}

.tag[data-type="LIBRARY"] {
    color: #607D8B;
}

.tag[data-type="TRADITIONAL"] {
    color: #FF9800;
}

.tag[data-type="RESTRICTION_CHECK"] {
    color: #009688;
}

.tag[data-type="CRISPR"] {
    color: #3F51B5;
}

.tag[data-type="PRIMER_DESIGN"] {
    color: #CDDC39;
}

.tag[data-type="SEAMLESS_CLONING"] {
    color: #FFC107;
}

.tag[data-type="RESTRICTION_ANALYSIS"] {
    color: #00BCD4;
}

.tag[data-type="VECTOR_SELECTION"] {
    color: #8BC34A;
}

.tag[data-type="TAG_LIBRARY"] {
    color: #673AB7;
}

.tag[data-type="PROJECT_MANAGEMENT"] {
    color: #FF4081;
}

.tag[data-type="GANTT_CHART"] {
    color: #03A9F4;
}

.tag[data-type="BILL_OF_MATERIALS"] {
    color: #FF5252;
}

.tag[data-type="HIGH_THROUGHPUT"] {
    color: #7C4DFF;
}

.tag[data-type="DNA_REUSE"] {
    color: #FF6E40;
}

.tag[data-type="AI"] {
    color: #1DE9B6;
}

.tag[data-type="OLIGO_TILING"] {
    color: #F50057;
}

.tag[data-type="DNA_SYNTHESIS"] {
    color: #76FF03;
}

.tag[data-type="ECHO_WORKLISTS"] {
    color: #FF3D00;
}

.tag[data-type="AUTOMATION"] {
    color: #00E5FF;
}

.tag[data-type="AUTOMATED_PLANNING"] {
    color: #C51162;
}

.tag[data-type="TARGET_SEQUENCE"] {
    color: #64DD17;
}

.tag[data-type="MACHINE_LEARNING"] {
    color: #AA00FF;
}

.tag[data-type="STRATEGY_OPTIMIZATION"] {
    color: #FFD600;
}

.tag[data-type="ROBOTICS"] {
    color: #00B8D4;
}

.tag[data-type="WORKFLOW_GENERATION"] {
    color: #AEEA00;
}

.tag[data-type="EXPRESSION_TESTING"] {
    color: #00B8D4;
}

.tag[data-type="TUTORIAL"] {
    color: #FF3D00;
}

.tag[data-type="BINDERS"] {
    color: #FF3D00;
}


.sectionTitle {
    font-size: var(--h3-size);
    margin-top: 40px;
    margin-bottom: 20px;
    color: var(--black);
}

.futureCard {
    background: linear-gradient(135deg, #f0f0f0, #e0e0e0);
    border-radius: var(--br-xl);
    padding: 20px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    height: auto;
    min-height: 180px;
    position: relative;
    overflow: hidden;
    cursor: not-allowed;
}

.futureCard::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.05);
    z-index: 1;
}

.futureCard:hover::after {
    content: 'Coming Soon!';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: var(--p1-size);
    z-index: 2;
}

.futureCard .cardTitle,
.futureCard .authorInfo,
.futureCard .date,
.futureCard .tags {
    opacity: 0.7;
}

.futureCard .tag {
    background-color: rgba(0, 0, 0, 0.1);
    color: var(--color-gray-100);
}

.cardFooter {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    /* Align items to the bottom */
    margin-top: auto;
    /* Push the footer to the bottom of the card */
}

.card:focus,
.card:focus-visible {
    outline: 3px solid var(--color-primary);
    outline-offset: 2px;
    transform: translateY(-5px);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

.card:focus:not(:focus-visible) {
    outline: none;
}

/* Make sure interactive elements have visible focus states */
.tag:focus-visible {
    outline: 2px solid var(--color-primary);
    outline-offset: 2px;
}

/* Ensure sufficient color contrast for tags */
.tag {
    /* ... existing styles ... */
    color: var(--black); /* Ensure text is readable */
    background-color: rgba(255, 255, 255, 0.9); /* Increase opacity for better contrast */
}

/* Add focus styles for future cards */
.futureCard:focus-visible {
    outline: 3px solid var(--color-primary);
    outline-offset: 2px;
}

/* Improve hover message contrast */
.futureCard:hover::after {
    background-color: rgba(0, 0, 0, 0.9);
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: var(--p1-size);
    z-index: 2;
}