.footerCtaShape {
  height: 7.313rem;
  width: 41.5rem;
  position: relative;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: var(--br-xl);
  background-color: var(--gradient-full);
  display: none;
  max-width: 100%;
}

.footerCta {
  margin: 0;
  position: relative;
  font-size: inherit;
  font-weight: 700;
  font-family: inherit;
  z-index: 1;
  flex: 1;
}

.footerCtaShapeParent,
.imageDescription {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;
  max-width: 100%;
}

.footerCtaShapeParent {
  flex: 1;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: var(--br-xl);
  background-color: var(--gradient-full);
  padding: var(--padding-17xl) var(--padding-31xl);
  z-index: 2;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  width: 100%;
  /* Add this line */
  max-width: 41.5rem;
  /* Add this line */
  margin: 0 auto;
  /* Add this line to center the element */
}

.imageDescription {
  align-self: stretch;
  padding: 0 var(--padding-xl);
  /* Reduce padding to allow more space */
  display: flex;
  /* Add this line */
  justify-content: center;
  /* Add this line */
}

.footer1 {
  height: 20.5rem;
  width: 90rem;
  position: relative;
  background-color: var(--color);
  display: none;
  max-width: 100%;
}

.footerLogoIcon {
  width: 11rem;
  height: 2.563rem;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}

.labkickAllRights {
  align-self: stretch;
  position: relative;
  line-height: 1.113rem;
}

.footerBranding {
  width: 16.125rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-2xl);
}

.image6Icon {
  height: 1.875rem;
  width: 1.875rem;
  position: relative;
  border-radius: var(--br-47xl);
  object-fit: cover;
}

.socialIcons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-xl);
}

.contactInfo,
.legalStuff {
  position: relative;
  line-height: 1.113rem;
  display: inline-block;
  min-width: 5.125rem;
  text-decoration: none; /* Remove underline */
  color: inherit; /* Inherit text color */
  margin-right: 10px; /* Add space between elements */
}

/* footer llinks third a  */
.termsOfService {
  margin-right: 0;
}

.legalStuff {
  text-decoration: none;
  color: inherit;
  min-width: 4.375rem;
  width: fit-content;
}

.footerLinks,
.footerTop {
  display: flex;
  flex-direction: row;
}

.footerLinks {
  align-items: flex-end;
  justify-content: flex-end;
  flex-wrap: wrap;
  align-content: flex-end;
  gap: 1.25rem 1.562rem;
  color: var(--gradient-full);
  width: 50%;
  display: flex;
  flex-direction: column;
}

.footerTop {
  width: 67.125rem;
  align-items: center;
  justify-content: space-between;
  gap: var(--gap-xl);
  max-width: 100%;
  z-index: 1;
}

.footer,
.imageDescriptionParent {
  align-self: stretch;
  display: flex;
  align-items: flex-start;
  max-width: 100%;
  text-align: left;
}

.footer {
  background-color: var(--color);
  flex-direction: row;
  justify-content: center;
  padding: var(--padding-154xl) var(--padding-xl) var(--padding-53xl);
  box-sizing: border-box;
  margin-top: -3.625rem;
  font-size: var(--p2-size);
  color: var(--color-gray-100);
  font-family: var(--p2);
}

.imageDescriptionParent {
  flex-direction: column;
  justify-content: flex-start;
  font-size: var(--h2-size);
  color: var(--color);
  font-family: var(--h2);
}

.signUpButton {
  width: auto;
  min-width: 120px;
  margin-left: 20px;
  flex-shrink: 0;
  white-space: nowrap;
}

@media screen and (max-width: 825px) {
  .footerCta {
    font-size: var(--font-size-16xl);
  }

  .imageDescription {
    padding-left: var(--padding-175xl);
    padding-right: var(--padding-175xl);
    box-sizing: border-box;
  }

  .footerTop {
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 450px) {
  .footerCta {
    font-size: var(--font-size-7xl);
  }

  .footerCtaShapeParent,
  .imageDescription {
    padding-left: var(--padding-xl);
    padding-right: var(--padding-xl);
    box-sizing: border-box;
  }
}

@media screen and (max-width: 768px) {
  .footerCtaShapeParent {
    flex-direction: column;
    align-items: center;
    gap: 20px;
    padding: var(--padding-xl);
    width: 80%;
    /* Ensure full width on mobile */
    max-width: 80%;
    /* Allow full width on mobile */
  }

  .footerCta {
    text-align: center;
    font-size: 24px;
  }

  .signUpButton {
    align-self: center;
    width: auto;
    margin: 0 auto;
    display: flex;
    justify-content: center;
  }

  .footerTop {
    flex-direction: column;
    align-items: center;
    gap: 30px;
  }

  .footerBranding {
    width: 100%;
    align-items: center;
    text-align: center;
  }

  .footerLinks {
    width: 100%;
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }

  .socialIcons {
    justify-content: center;
    margin-top: 20px;
    width: 100%;
    gap: 20px; /* Slightly increased gap for mobile */
  }

  .footerLinksMobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    margin-top: 20px;
  }

  .contactInfo,
  .legalStuff {
    text-align: center;
    width: fit-content;
    text-wrap: nowrap;
  }

  .imageDescription {
    padding-left: var(--padding-md);
    padding-right: var(--padding-md);
  }

  .footer {
    padding: var(--padding-xl);
    margin-top: 0;
  }
}

@media screen and (max-width: 480px) {
  .footerCta {
    font-size: 20px;
  }

  .footerLogoIcon {
    width: 8rem;
    height: auto;
  }

  .socialIcons {
    gap: 24px; /* Slightly more gap for very small screens */
  }

  .image6Icon {
    height: 1.5rem;
    width: 1.5rem;
  }
}

/* Add focus styles for interactive elements */
.footerLink:focus,
.socialLink:focus {
  outline: 2px solid #fff;
  outline-offset: 2px;
}

.footerLink:focus-visible,
.socialLink:focus-visible {
  outline: 2px solid #fff;
  outline-offset: 2px;
}

/* Add hover states */
.footerLink:hover,
.socialLink:hover {
  text-decoration: underline;
}

/* Ensure proper focus visibility for the sign-up button */
.signUpButton:focus {
  outline: 2px solid #fff;
  outline-offset: 2px;
}

/* Add skip-link for keyboard navigation */
.skipLink {
  position: absolute;
  left: -9999px;
  z-index: 999;
  padding: 1em;
  background-color: white;
  color: black;
  text-decoration: none;
}

.skipLink:focus {
  left: 50%;
  transform: translateX(-50%);
}

/* Ensure proper contrast for focus indicators */
@media (prefers-reduced-motion: reduce) {
  * {
    transition: none !important;
  }
}
