.testimonial2Child {
  height: 17.313rem;
  width: 32.125rem;
  position: relative;
  border-radius: var(--br-xl);
  background-color: var(--color);
  border: 2px solid var(--black);
  box-sizing: border-box;
  display: none;
  max-width: 100%;
}
.quoteMarkIcon {
  height: 5.125rem;
  width: 5.125rem;
  position: relative;
  border-radius: 50%;
  object-fit: cover;
  z-index: 1;
}
.withoutLabkickI {
  position: relative;
  line-height: 1.563rem;
  z-index: 1;
}
.authorDetailsChild {
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: contain;
  margin-top: -0.25rem;
}
.authorDetailsChild,
.leonardAdleman {
  align-self: stretch;
  position: relative;
  z-index: 1;
}
.authorDetails {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-mini);
}
.computerScientist {
  align-self: stretch;
  position: relative;
  font-size: var(--p2-size);
  line-height: 1.113rem;
  font-family: var(--p2);
  color: var(--pink);
  z-index: 1;
}
.authorInfo {
  align-self: stretch;
  gap: var(--gap-lg);
  font-size: var(--h4-size);
  font-family: var(--h2);
}
.authorInfo,
.testimonial2,
.testimonialContent,
.withoutLabkickIDontThinkParent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.withoutLabkickIDontThinkParent {
  align-self: stretch;
  gap: var(--gap-11xl);
}
.testimonial2,
.testimonialContent {
  width: 18.875rem;
  padding: var(--padding-12xs) 0 0;
  box-sizing: border-box;
}
.testimonial2 {
  width: 32.125rem;
  margin: 0 !important;
  position: absolute;
  top: 0;
  left: 37.625rem;
  border-radius: var(--br-xl);
  background-color: var(--color);
  border: 2px solid var(--black);
  flex-direction: row;
  padding: var(--padding-17xl) var(--padding-19xl) var(--padding-10xl);
  gap: var(--gap-22xl);
  max-width: 100%;
  text-align: left;
  font-size: var(--p1-size);
  color: var(--black);
  font-family: var(--p2);
}
@media screen and (max-width: 825px) {
  .testimonial2 {
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 450px) {
  .leonardAdleman {
    font-size: var(--font-size-lgi);
  }
}
