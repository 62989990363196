.paymentTierOneChild {
  position: relative;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: var(--br-xl);
  background-color: var(--color-whitesmoke);
  display: none;
  max-width: 100%;
}
.academic {
  margin: 0;
  position: relative;
  font-size: inherit;
  font-weight: 600;
  font-family: inherit;
  z-index: 1;
}
.perLabchip,
.priceSymbol {
  position: relative;
  line-height: 4.056rem;
  z-index: 1;
}
.perLabchip {
  align-self: stretch;
  font-size: var(--p1-size);
  line-height: 1.563rem;
  font-family: var(--p2);
  color: var(--black);
}
.academicPricing {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-10xs);
  font-size: var(--h1-size);
  color: var(--gradient-full);
}
.purchaseNow {
  position: relative;
  font-size: var(--h4-size);
  font-family: var(--h2);
  color: var(--color);
  text-align: left;
}
.academicContent,
.signUp {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.signUp {
  cursor: pointer;
  border: 2px solid var(--color);
  padding: var(--padding-5xs) var(--padding-2xs) var(--padding-5xs)
    var(--padding-base);
  background-color: var(--gradient-full1);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: var(--br-12xl);
  background: linear-gradient(90deg, #ff8c00, #c4002f 50%, #d93dea);
  flex-direction: row;
  z-index: 1;
}
.signUp:hover,
.signUp:focus {
  background-color: var(--color-gainsboro-200);
  border: 2px solid var(--color-gainsboro-100);
  box-sizing: border-box;
}
.academicContent {
  align-self: stretch;
  flex-direction: column;
  gap: var(--gap-base);
}
.chatsPerMonth {
  margin: 0;
}
.chatsPerMonthContainer,
.divider {
  align-self: stretch;
  position: relative;
  z-index: 1;
}
.chatsPerMonthContainer {
  line-height: 2.063rem;
}
.divider {
  height: 0.063rem;
  border-top: 1px solid var(--color-darkgray);
  box-sizing: border-box;
}
.securityHighlight {
  align-self: stretch;
  gap: var(--gap-2xl);
}
.paymentTierOne,
.academicDetails,
.securityHighlight {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.academicDetails {
  align-self: stretch;
  gap: var(--gap-mid);
  font-size: var(--p1-size);
  font-family: var(--p2);
}
.paymentTierOne {
  flex: 1;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: var(--br-xl);
  background-color: var(--color-whitesmoke);
  border: 2px solid var(--color);
  box-sizing: border-box;
  padding: var(--padding-13xl) var(--padding-13xl) var(--padding-9xl);
  gap: var(--gap-18xl);
  max-width: 100%;
  z-index: 1;
  text-align: left;
  font-size: var(--h3-size);
  color: var(--black);
  font-family: var(--h2);
  min-width: 300px;
  max-width: 400px;
  margin: 0 auto;
}
@media screen and (max-width: 825px) {
  .academic {
    font-size: var(--font-size-7xl);
  }
  .priceSymbol {
    font-size: var(--font-size-29xl);
    line-height: 3.25rem;
  }
}
@media screen and (max-width: 450px) {
  .academic {
    font-size: var(--font-size-lgi);
  }
  .priceSymbol {
    font-size: var(--font-size-17xl);
    line-height: 2.438rem;
  }
  .paymentTierOne {
    gap: var(--gap-lg);
    padding-top: var(--padding-2xl);
    padding-bottom: var(--padding-xl);
    box-sizing: border-box;
    min-width: 200px;
  }
}
.topFeature {
  font-weight: 700;
  font-size: var(--h4-size);
  /* margin-bottom: 1.5rem; */
  color: var(--black);
}
.featureList {
  list-style: none;
  padding-left: 0;
  margin: 0;
}
.featureItem {
  margin: 0.5rem 0;
  line-height: 1.5;
  padding-left: 1.8rem;
  position: relative;
}
.featureItem::before {
  content: "✓";
  position: absolute;
  left: 0;
  color: var(--gradient-full);
  font-weight: bold;
}
.signUp:focus {
  outline: 2px solid #4a90e2;
  outline-offset: 2px;
}
.signUp:focus:not(:focus-visible) {
  outline: none;
}
.signUp:focus-visible {
  outline: 2px solid #4a90e2;
  outline-offset: 2px;
}
.freePrice {
  font-size: var(--h1-size);
  font-weight: bold;
}
