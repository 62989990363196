.paymentTierThreeChild {
  /* width: 20.875rem;
  height: 33.75rem; */
  position: relative;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: var(--br-xl);
  background-color: var(--color-whitesmoke);
  border: 2px solid var(--color-darkorchid);
  box-sizing: border-box;
  display: none;
  max-width: 100%;
}
.enterprise {
  margin: 0;
  position: relative;
  font-size: inherit;
  font-weight: 600;
  font-family: inherit;
  z-index: 1;
}
.enterpriseContent {
  width: 9.313rem;
  height: 2.25rem;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 0 var(--padding-9xs);
  box-sizing: border-box;
  font-size: var(--h3-size);
  font-family: var(--h2);
}
.customSolutionsAvailable {
  margin: 0;
}
.customSolutionsAvailableForContainer {
  flex: 1;
  position: relative;
  line-height: 1.438rem;
  z-index: 1;
}
.enterpriseDescription {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 0 var(--padding-5xs);
}
.contactUs {
  position: relative;
  font-size: var(--h4-size);
  font-family: var(--h2);
  color: var(--gradient-full);
  text-align: left;
  display: inline-block;
  min-width: 6.875rem;
}
.signUp {
  cursor: pointer;
  border: 2px solid var(--gradient-full);
  padding: var(--padding-5xs) var(--padding-2xs) var(--padding-5xs)
    var(--padding-base);
  background-color: var(--color);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: var(--br-12xl);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  z-index: 1;
}
.signUp:hover {
  background-color: var(--color-gainsboro-100);
  border: 2px solid var(--color-chocolate);
  box-sizing: border-box;
}
.divider,
.unlimitedChatsPerContainer {
  align-self: stretch;
  position: relative;
  z-index: 1;
}
.unlimitedChatsPerContainer {
  line-height: 2.063rem;
}
.divider {
  height: 0.063rem;
  border-top: 1px solid var(--color-darkgray);
  box-sizing: border-box;
}
.planInfo {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 0 var(--padding-12xs);
  gap: var(--gap-mid);
}
.enterpriseGradeSecurity {
  margin: 0;
  line-height: false;
}
.elnProject {
  line-height: false;
}
.enterpriseGradeSecurityElnContainer {
  flex: 1;
  position: relative;
  z-index: 1;
}
.paymentTierThree,
.security {
  align-self: stretch;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-xl);
}
.paymentTierThree {
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: var(--br-xl);
  background-color: var(--color-whitesmoke);
  border: 2px solid var(--color-darkorchid);
  box-sizing: border-box;
  padding: var(--padding-13xl) var(--padding-13xl) var(--padding-8xl);
  gap: var(--gap-18xl);
  max-width: 100%;
  z-index: 1;
  text-align: left;
  font-size: var(--p1-size);
  color: var(--black);
  font-family: var(--p2);
  min-width: 300px;
  max-width: 400px;
  margin: 0 auto;
}
@media screen and (max-width: 825px) {
  .enterprise {
    font-size: var(--font-size-7xl);
  }
  .paymentTierThree {
    padding-top: var(--padding-2xl);
    padding-bottom: var(--padding-xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 450px) {
  .enterprise {
    font-size: var(--font-size-lgi);
  }
  .paymentTierThree {
    gap: var(--gap-lg);
    min-width: 200px;
  }
}
.signUp:focus {
  outline: 2px solid #4a90e2;
  outline-offset: 2px;
}

.signUp:focus:not(:focus-visible) {
  outline: none;
}

.signUp:focus-visible {
  outline: 2px solid #4a90e2;
  outline-offset: 2px;
}

.signUp:hover,
.signUp:focus {
  background-color: var(--color-gainsboro-100);
  border: 2px solid var(--color-chocolate);
  box-sizing: border-box;
}

.topFeature {
  font-weight: 700;
  font-size: var(--h4-size);
  /* margin-bottom: 1.5rem; */
  color: var(--black);
}

.featureList {
  list-style: none;
  padding-left: 0;
  margin: 0;
}

.featureItem {
  margin: 0.5rem 0;
  line-height: 1.5;
  padding-left: 1.8rem;
  position: relative;
}

.featureItem::before {
  content: "✓";
  position: absolute;
  left: 0;
  color: var(--gradient-full);
  font-weight: bold;
}
