.inputBackground {
  margin-left: -95.063rem;
  height: 30.5rem;
  position: relative;
  background: linear-gradient(
    0deg,
    rgba(222, 222, 222, 0.87),
    rgba(255, 255, 255, 0) 79.22%
  );
  min-width: 90rem;
  flex-shrink: 0;
  max-width: 118%;
}

.actionBoxWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex: 1;
  align-items: stretch;
}

.actionBoxWrapper {
  background-color: var(--gradient-full);
}

.right .actionBox {
  justify-content: flex-end;
}

.left .actionBox {
  justify-content: flex-start;
}

.textBox {
  /* position: relative;
  background-color: var(--gradient-full);
  display: none;
  max-width: 100%; */

  position: relative;
  background-color: var(--gradient-full);
  display: block;
  max-width: 500px;
  min-width: 200px;
  width: 100%;
  flex-grow: 1;
  flex-shrink: 1;
}

.header {
  margin: 0;
  position: relative;
  font-size: inherit;
  font-weight: 600;
  font-family: inherit;
  z-index: 1;
}

.imageWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
}

.textBoxContainer {
  padding-top: 25px;
  padding-bottom: 25px;
  gap: 0 !important;
}

.textBoxContainer h2 {
  margin-bottom: 15px;
}

.left {
  margin-right: auto;
}

.left .textBoxContainer {
  margin-left: auto;
  padding-right: 50px;
  /* margin-left: 10px; */
}

.right .textBoxContainer {
  padding-left: 50px;
  /* margin-left: 10px; */
  margin-right: auto;
}

.right {
  margin-left: auto;
}

.right,
.left {
  pointer-events: auto;
  display: flex;
  width: 40%;
}

.imageWrapperLeft,
.imageWrapperRight {
  max-width: 550px;
  z-index: 6;
  min-width: 300px;
  width: 40%;
  margin: auto;
}

.imageWrapperLeft {
  justify-content: flex-end;
  /* margin-right: 10%; */
}

.imageWrapperRight {
  justify-content: flex-start;
  /* margin-left: 10%; */
}

.image {
  width: calc(100% - 6px);
  pointer-events: auto;
  border-radius: 23px;
  border: 3px solid #ff8c00;
  cursor: pointer;
}

.image:focus {
  outline: 3px solid #4a90e2;
  outline-offset: 2px;
}

.image:hover {
  transform: none;
}

.body {
  font-size: var(--p1-size);
  line-height: 1.7;
  font-family: var(--p2);
  color: var(--black);
  max-width: 800px;
  width: 100%;
  margin: 0 auto;
  position: relative;
  z-index: 12;
  font-weight: 600;
}

.textBoxContainer {
  flex: 1;
  background-color: var(--gradient-full);
  flex-direction: column;
  justify-content: flex-start;
  pointer-events: auto;
  margin-right: 0;
  width: fit-content;
  /* padding: var(--padding-15xl) var(--padding-xl) var(--padding-2xl)
    var(--padding-239xl); */
  box-sizing: border-box;
  gap: var(--gap-lg);
  max-width: 100%;
  z-index: 5;
}

.textBoxContainer,
.textBox1,
.textBoxWrapper {
  display: flex;
  align-items: flex-start;
}

.textBox1 {
  width: 58.25rem;
  background-color: var(--gradient-full);
  flex-direction: column;
  justify-content: flex-start;
  padding: var(--padding-12xl) var(--padding-xl) var(--padding-5xl)
    var(--padding-91xl);
  box-sizing: border-box;
  gap: var(--gap-lg);
  max-width: 100%;
  z-index: 5;
}

.textBoxWrapper {
  width: 83.5rem;
  flex-direction: row;
  justify-content: flex-end;
}

.actionBox,
.actionBoxWrapper,
.textBoxParent {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.textBoxParent {
  gap: var(--gap-182xl);
  flex-shrink: 0;
  max-width: 100%;
}

.actionBox,
.actionBoxWrapper {
  box-sizing: border-box;
  max-width: 100%;
}

.actionBox {
  align-self: stretch;
  align-items: center;
  flex-direction: row;
  text-align: left;
  font-size: var(--h3-size);
  color: var(--black);
  font-family: var(--h2);
  z-index: 5;
  width: 100%;
  gap: 0;
  max-width: 85%;
}

@media screen and (max-width: 1400px) {
  .inputBackground {
    flex: 1;
    min-width: 100%;
  }

  .textBoxParent {
    gap: var(--gap-81xl);
  }

  .actionBox {
    flex-wrap: wrap;
    box-sizing: border-box;
  }
}

@media screen and (max-width: 1200px) {
  .actionBox {
    max-width: calc(100% - 25px);
  }
}

@media screen and (max-width: 765px) {
  .imageWrapperLeft,
  .imageWrapperRight {
    margin-bottom: -20px;
  }
}

@media screen and (max-width: 900px) {
  .actionBox {
    max-width: 100%;
  }

  .textBoxContainer {
    padding-top: 30px;
    padding-bottom: 30px;
    margin: auto;
  }
}

@media screen and (max-width: 450x) {
  .imageWrapperLeft,
  .imageWrapperRight {
    min-width: 100%;
  }
}

/* @media screen and (max-width: 875px) {
  .imageWrapperLeft,
  .imageWrapperRight {
    order: 0;
  }
  .textBoxContainer {
    order: 1;
  }
} */

/* @media screen and (max-width: 825px) {
  .header {
    font-size: var(--font-size-7xl);
  }
  .textBoxContainer,
  .textBox1 {
    padding-left: var(--padding-110xl);
    box-sizing: border-box;
  }
  .textBox1 {
    padding-left: var(--padding-36xl);
  }
  .actionBox {
    gap: var(--gap-xl);
    padding-left: var(--padding-7xl);
    padding-right: var(--padding-7xl);
    padding-bottom: var(--padding-74xl);
    box-sizing: border-box;
  }
} */
/* @media screen and (max-width: 450px) {
  .header {
    font-size: var(--font-size-lgi);
  }
  .textBoxContainer,
  .textBox1 {
    padding-left: var(--padding-xl);
    box-sizing: border-box;
  }
  .actionBox {
    padding-bottom: var(--padding-41xl);
    box-sizing: border-box;
  }
} */

.actionBoxContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  margin: 1rem auto;
  padding: 0.5rem 1rem;
  position: relative;
  z-index: 10;
}

.textContent {
  text-align: center;
  margin-bottom: 1rem;
  max-width: 1200px;
  width: 600px;
  position: relative;
  z-index: 11;
}

.header {
  font-size: 2.5rem;
  font-weight: 600;
  margin-bottom: 0.75rem;
  font-family: var(--h2);
  position: relative;
  z-index: 12;
  color: var(--black);
  line-height: 1.2;
}

.imageWrapper {
  width: 80%;
  margin: 0 auto;
  position: relative;
  z-index: 11;
}

.image {
  width: 100%;
  border-radius: 23px;
  border: 3px solid #ff8c00;
  cursor: pointer;
  transition: transform 0.2s ease;
  position: relative;
  z-index: 11;
}

.image:focus {
  outline: 3px solid #4a90e2;
  outline-offset: 2px;
}

@media screen and (max-width: 768px) {
  .actionBoxContainer {
    padding: 0.5rem 1rem;
    margin: 0.5rem auto;
    width: calc(100% - 2rem);
    text-align: center;
  }

  .textContent {
    width: 100%;
    padding: 0 1rem;
    margin: 0 auto;
    text-align: center;
  }

  .header {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }

  .body {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }

  .right,
  .left {
    width: calc(100% - 2rem);
    margin: 0 auto;
  }

  .right .textBoxContainer,
  .left .textBoxContainer {
    padding: 15px;
    width: 100%;
    margin: 0 auto;
  }

  .actionBox {
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    padding: 0;
    width: 100%;
    margin: 0 auto;
  }

  .imageWrapperLeft,
  .imageWrapperRight {
    width: 100%;
    min-width: unset;
    padding: 0 1rem;
    margin: 0 auto;
  }
}

@media screen and (max-width: 480px) {
  .actionBoxContainer {
    padding: 0.25rem 1rem;
    width: calc(100% - 2rem);
  }

  .imageWrapperLeft,
  .imageWrapperRight {
    padding: 0 1rem;
  }

  .textContent {
    padding: 0 1rem;
    text-align: center;
  }

  .header,
  .body {
    text-align: center;
  }
}
