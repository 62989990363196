.headerContainer {
  margin: 0;
  flex: 1;
  position: relative;
  font-size: inherit;
  font-weight: 600;
  font-family: inherit;
}

.headerContainerWrapper {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-8xl);
}

.header {
  flex: 1;
  position: relative;
  line-height: 37.5px;
  margin: 0;
  font-weight: 400;
}

.featureDetails {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-8xl);
  font-size: var(--p1-size);
  font-family: var(--p2);
}

.frameChild {
  height: 4.163rem;
  width: 4.813rem;
  position: relative;
}

.talkContainerInner {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 0 var(--padding-xl);
}

.orangeBorderBottom {
  align-self: auto;
  width: 100%;
  height: 1.656rem;
  display: flex;
  position: relative;
  border-radius: 0 0 var(--br-xl) var(--br-xl);
  background-color: var(--gradient-full);
  margin-top: auto;
  bottom: -2px;
}

.talkContainer {
  width: 20.625rem;
  margin: 0 !important;
  position: relative;
  border-radius: var(--br-2xl);
  background-color: var(--color);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-7xl-5) 0 var(--padding-12xs-1);
  box-sizing: border-box;
  gap: var(--gap-base);
  text-align: left;
  font-size: var(--h3-size);
  color: var(--black);
  font-family: var(--h2);
}

.lightbulbIcon {
  height: 4.156rem;
  width: 4.094rem;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}

.lightbulbWrapper {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 0 var(--padding-xl);
}

.spacer {
  align-self: stretch;
  height: 1.656rem;
  position: relative;
  border-radius: 0 0 var(--br-xl) var(--br-xl);
  background-color: var(--gradient-full);
}

@media screen and (max-width: 825px) {
  .headerContainer {
    font-size: var(--font-size-7xl);
  }
}

.talkContainer:focus {
  outline: 2px solid var(--black);
  outline-offset: 2px;
}

.talkContainer:focus:not(:focus-visible) {
  outline: none;
}