.filledButtonCta {
  position: relative;
  font-size: var(--h4-size);
  font-family: var(--h2);
  color: var(--color);
  white-space: pre-wrap;
  text-align: center;
  text-wrap: nowrap !important;
  margin: auto;
}

.customButton {
  cursor: pointer;
  border: 2px solid var(--color);
  padding: var(--padding-6xs) var(--padding-2xs) var(--padding-6xs) var(--padding-base);
  background-color: var(--gradient-full1);
  height: 3.063rem;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: var(--br-xl);
  background: linear-gradient(90deg, #ff8c00, #c4002f 50%, #d93dea);
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  z-index: 9999;
  width: fit-content;
  margin: auto;
}

.customButton:focus-visible {
  outline: 3px solid #4D90FE;
  outline-offset: 2px;
}

.customButton:hover,
.customButton:focus {
  background-color: var(--color-gainsboro-200);
  border: 2px solid var(--color-gainsboro-100);
  box-sizing: border-box;
  margin: auto;
}

@media screen and (max-width: 480px) {
  .filledButtonCta {
    font-size: 1.2rem;
  }
}

/* comment */