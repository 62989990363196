.circleGraphic,
.graphicElements {
  position: relative;
  border-radius: 50%;
}

.circleGraphic {
  width: 1.063rem;
  height: 1.063rem;
  background: linear-gradient(90deg, #ff8c00, #c4002f 50%, #d93dea);
}

.graphicElements {
  height: 1rem;
  width: 0.938rem;
  background-color: var(--black);
}

.graphicElementsWrapper,
.indiciators {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.graphicElementsWrapper {
  flex-direction: row;
  padding: 0 var(--padding-12xs);
}

.indiciators {
  top: 22.375rem;
  left: 4.938rem;
  flex-direction: column;
  gap: 0.562rem;
}

.heroImagevideo,
.indiciators,
.logoIcon {
  position: absolute;
  margin: 0 !important;
  z-index: 3;
}

.logoIcon {
  width: 11rem;
  height: 2.563rem;
  top: 2.375rem;
  left: 11.313rem;
  overflow: hidden;
  flex-shrink: 0;
}

.heroImagevideo {
  width: 42.5rem;
  height: 20.063rem;
  top: 24.5rem;
  left: calc(50% - 340px);
  box-shadow: 0 0 12px 6px rgba(0, 0, 0, 0.25);
  border-radius: var(--br-xl);
  background-color: var(--color);
}

.image9Icon {
  height: 20.125rem;
  flex: 1;
  position: relative;
  border-radius: var(--br-xl);
  max-width: 100%;
  overflow: hidden;
  object-fit: cover;
  z-index: 4;
}

.iconPlayCircle,
.image9Parent {
  margin: 0 !important;
  position: absolute;
}

.iconPlayCircle {
  height: 4.213rem;
  width: 4.188rem;
  top: 7.563rem;
  left: 19.063rem;
  z-index: 6;
}

.image9Parent {
  width: 42.438rem;
  top: 24.5rem;
  left: 23.813rem;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}

.background,
.gradient {
  position: absolute;
  top: 25rem;
}

.background {
  left: 0;
  background: linear-gradient(0deg, #d9d9d9 74.5%, rgba(255, 255, 255, 0));
  width: 100%;
  top: 0;
  height: 100%;
}

.gradient {
  left: 0;
  background: linear-gradient(180deg, #fff 8%, rgba(255, 255, 255, 0) 30%);
  width: 100%;
  height: 100%;
  top: 0;
  z-index: 3;
}

.coloredLineShapes1Icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  z-index: 2;
}

.exampleIcon {
  position: absolute;
  top: 40.1rem;
  left: 85.9rem;
  border-radius: var(--br-xl);
  width: 33.25rem;
  height: 19.5rem;
  object-fit: cover;
  z-index: 6;
}

.exampleIcon1 {
  top: 65.788rem;
  right: 48.313rem;
}

.exampleIcon1,
.exampleIcon2,
.exampleIcon3 {
  position: absolute;
  border-radius: var(--br-xl);
  width: 33.25rem;
  height: 19.5rem;
  object-fit: cover;
  z-index: 6;
}

.exampleIcon2 {
  bottom: 54.85rem;
  left: 85.588rem;
}

.exampleIcon3 {
  right: 48rem;
  bottom: 29.163rem;
}

.backgroundParent {
  height: 100%;
  width: 100%;
  position: absolute;
  margin: 0 !important;
  top: 0;
  left: 0;
  z-index: 3;
}

.bottomGraphicsChild {
  width: 58.25rem;
  height: 13.125rem;
  position: relative;
  background-color: var(--gradient-full);
  display: none;
  max-width: 100%;
}

.createACombinatorial,
.uploadYourSequences {
  position: relative;
  display: inline-block;
  max-width: 100%;
  z-index: 6;
}

.uploadYourSequences {
  margin: 0;
  font-size: inherit;
  font-weight: 600;
  font-family: inherit;
}

.createACombinatorial {
  width: 25.938rem;
  height: 6.25rem;
  font-size: var(--p1-size);
  line-height: 1.563rem;
  font-family: var(--p2);
}

.bottomGraphics,
.bottomGraphicsWrapper {
  display: flex;
  align-items: flex-start;
  box-sizing: border-box;
  max-width: 100%;
}

.bottomGraphics {
  flex: 1;
  background-color: var(--gradient-full);
  flex-direction: column;
  justify-content: flex-start;
  padding: var(--padding-12xl) var(--padding-xl) var(--padding-10xl)
    var(--padding-101xl);
  gap: var(--gap-lg);
  z-index: 5;
}

.bottomGraphicsWrapper {
  width: 58.663rem;
  flex-direction: row;
  justify-content: flex-end;
  padding: 0 0.187rem 7.625rem;
  text-align: left;
  font-size: var(--h3-size);
  color: var(--black);
  font-family: var(--h2);
}

.shareYourOutputs {
  margin: 0;
  position: relative;
  font-size: inherit;
  font-weight: 600;
  font-family: inherit;
  z-index: 6;
}

.downloadToReview {
  width: 25.938rem;
  position: relative;
  font-size: var(--p1-size);
  line-height: 1.563rem;
  display: inline-block;
  font-family: var(--p2);
  max-width: 100%;
  z-index: 6;
}

.featureList,
.featureListWrapper {
  display: flex;
  align-items: flex-start;
  box-sizing: border-box;
  max-width: 100%;
}

.featureList {
  flex: 1;
  background-color: var(--gradient-full);
  flex-direction: column;
  justify-content: flex-start;
  padding: 2.062rem var(--padding-xl) 3.062rem 16.75rem;
  gap: var(--gap-lg);
  z-index: 5;
}

.featureListWrapper {
  align-self: stretch;
  flex-direction: row;
  justify-content: flex-end;
  padding: 0 25.437rem 9.4rem 6.25rem;
  text-align: left;
  font-size: var(--h3-size);
  color: var(--black);
  font-family: var(--h2);
}

.paymentPanelChild {
  width: 100%;
  height: 11.625rem;
  position: absolute;
  margin: 0 !important;
  bottom: 15.438rem;
  background: linear-gradient(90deg, #ff8c00, #c4002f 50%, #d93dea);
  opacity: 0.75;
}

.builtForYour,
.whetherYouKick {
  align-self: stretch;
  position: relative;
}

.builtForYour {
  margin: 0;
  font-size: inherit;
  font-weight: 700;
  font-family: inherit;
}

.whetherYouKick {
  font-size: var(--p1-size);
  line-height: 1.563rem;
  font-family: var(--p2);
  color: var(--black);
}

.labStatement,
.paymentContent,
.pricingTiers {
  display: flex;
  align-items: flex-start;
  max-width: 100%;
}

.labStatement {
  width: 47.063rem;
  flex-direction: column;
  justify-content: flex-start;
  gap: 1.187rem;
}

.paymentContent,
.pricingTiers {
  align-self: stretch;
  flex-direction: row;
  justify-content: center;
}

.pricingTiers {
  height: 33.5rem;
  padding: 0 10rem;
  justify-content: flex-start;
  gap: 2.75rem;
  margin-bottom: 5rem;
  height: fit-content;
}

.paymentPanel {
  position: relative;
  max-width: 103%;
}

.logoBannerIcon {
  position: relative;
  width: 104% !important;
  margin-left: -2%;
}

.paymentPanel {
  width: 100%;
  background-color: var(--color);
  padding-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;
  gap: 4.375rem;
  z-index: 5;
  text-align: center;
  font-size: var(--h2-size);
  color: var(--pink);
  font-family: var(--h2);
}

.logoBannerIcon {
  max-height: 100%;
  z-index: 1;
  margin-top: 37px;
  min-width: 100%;
  height: 3rem;
  width: auto;
  object-fit: cover;
}

.dynamicLinesIcon,
.frameChild {
  position: absolute;
  object-fit: contain;
}

.frameChild {
  top: 0;
  width: 100%;
}

.dynamicLinesIcon {
  top: 0rem;
  width: 100%;
  min-width: 100%;
  left: 0;
  z-index: 0;
}

.vectorParent {
  width: 100%;
  height: 147.375rem;
  position: absolute;
  margin: 0 !important;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
}

.signUpNow {
  position: relative;
}

.signUp {
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: var(--br-12xl);
  background: linear-gradient(90deg, #ff8c00, #c4002f 50%, #d93dea);
  border: 2px solid var(--color);
  box-sizing: border-box;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-6xs) 0.875rem;
  white-space: nowrap;
  width: 100%;
}

.example,
.signUpWrapper {
  position: relative;
  display: none;
}

.signUpWrapper {
  width: 10rem;
  height: 3.125rem;
  z-index: 15;
}

.example {
  font-size: 15.75rem;
  color: #1e00ff;
  transform: rotate(15deg);
  opacity: 0.2;
  max-width: 100%;
  z-index: 16;
}

.desktop29,
.desktop29Child {
  position: relative;
  overflow: hidden;
}

.desktop29Child {
  width: 6.25rem;
  height: 6.25rem;
  flex-shrink: 0;
  display: none;
  z-index: 17;
}

.desktop29 {
  width: 100%;
  background-color: #e5e5e5;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 0 !important;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  font-size: var(--h4-size);
  color: var(--color);
  font-family: var(--h2);
}

@media screen and (max-width: 1400px) {
  .pricingTiers {
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 1200px) {
  .paymentPanel {
    padding-top: var(--padding-22xl);
    padding-bottom: var(--padding-22xl);
    box-sizing: border-box;
  }
}

@media screen and (max-width: 825px) {
  .uploadYourSequences {
    font-size: var(--font-size-7xl);
  }

  .bottomGraphics {
    padding-left: var(--padding-41xl);
    box-sizing: border-box;
  }

  .shareYourOutputs {
    font-size: var(--font-size-7xl);
  }

  .featureList {
    padding-left: 8.375rem;
    box-sizing: border-box;
  }

  .featureListWrapper {
    padding-left: var(--padding-31xl);
    padding-right: 12.688rem;
    box-sizing: border-box;
  }

  .builtForYour {
    font-size: var(--font-size-16xl);
  }

  .pricingTiers {
    gap: var(--gap-3xl);
  }

  .paymentPanel {
    gap: 2.188rem;
    padding-left: var(--padding-60xl);
    padding-right: var(--padding-60xl);
    box-sizing: border-box;
    height: fit-content;
  }

  .paymentPanelChild {
    left: -6.187rem;
    width: calc(100% + 12rem);
  }

  .example {
    font-size: 6.313rem;
  }

  .desktop29 {
    gap: 1.813rem;
  }
}

@media screen and (max-width: 450px) {
  .uploadYourSequences {
    font-size: var(--font-size-lgi);
  }

  .bottomGraphics {
    padding-left: var(--padding-xl);
    box-sizing: border-box;
  }

  .shareYourOutputs {
    font-size: var(--font-size-lgi);
  }

  .featureList,
  .featureListWrapper {
    padding-left: var(--padding-xl);
    box-sizing: border-box;
  }

  .featureListWrapper {
    padding-right: var(--padding-xl);
    padding-bottom: 6.125rem;
  }

  .builtForYour {
    font-size: var(--font-size-7xl);
  }

  .paymentPanel {
    gap: var(--gap-mid);
    padding: var(--padding-8xl) var(--padding-xl);
    box-sizing: border-box;
  }

  .example {
    font-size: 3.938rem;
  }
}

.secondSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 3.625rem;
  background: white;
  z-index: 3;
  padding-bottom: 2rem;
  position: relative;
  width: 100%;
  z-index: 5;
  overflow: hidden;
}

.coloredLinesFixed {
  position: absolute;
  min-width: 100%;
  z-index: 5;
  top: 0;
}

.coloredLinesFixed img {
  min-width: 100%;
  margin-top: -250px;
}

.backgroundImage {
  top: -0.687rem;
  width: 97.688rem;
  flex-direction: column;
  align-items: flex-end;
  padding: var(--padding-22xl) var(--padding-183xl) var(--padding-935xl);
  box-sizing: border-box;
  gap: var(--gap-102xl);
}

.backgroundImageParent {
  align-self: stretch;
  height: 100%;
  position: relative;
  max-width: 100%;
}

.splashStatementText {
  flex: 1;
  position: relative;
  line-height: 1.563rem;
  display: inline-block;
  color: var(--black);
  max-width: 100%;
  z-index: 5;
  text-align: center;
}

.frameParent,
.splashStatement {
  display: flex;
  box-sizing: border-box;
  max-width: 100%;
}

.splashStatement {
  width: 95%;
  max-width: 800px;
  padding: 0;
  margin: auto;
  justify-content: center;
  margin-top: 100px;

  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
  font-size: var(--p1-size);
  font-family: var(--p2);
  top: 100px;
}

.questions {
  width: 100%;
  position: relative;
}

@media screen and (max-width: 900px) {
  .secondSection {
    padding-bottom: 0;
  }
}

/* @media screen and (max-width: 768px) {
  .responsiveQuestions {
    margin-top: 0.5rem;
  }
} */

@media screen and (max-width: 480px) {
  .responsiveQuestions {
    margin-top: 0.25rem;
    /* Even smaller margin for very small screens */
  }
}

@media screen and (max-width: 800px) {
  .pricingTiers {
    padding: 20px;
  }
}

.secondSection
  [class*="ActionBox_actionBoxWrapper__"][class*="right"]
  [class*="ActionBox_textBoxContainer__"] {
  text-align: left;
}

.skipNav {
  position: absolute;
  left: -9999px;
  z-index: 999;
  padding: 1em;
  background: white;
  color: black;
}

.skipNav:focus {
  left: 50%;
  transform: translateX(-50%);
}

/* Add focus styles for interactive elements */
:focus {
  outline: 2px solid #4A90E2;
  outline-offset: 2px;
}

:focus:not(:focus-visible) {
  outline: none;
}

:focus-visible {
  outline: 2px solid #4A90E2;
  outline-offset: 2px;
}
