.trustedByContainer {
  width: 100%;
  padding: 2rem 0;
  text-align: center;
  position: relative;
  z-index: 10;
  background-color: white;
}

.trustedByTitle {
  font-size: 1.2rem;
  font-weight: 700;
  margin-bottom: 2rem;
  color: #000000;
  position: relative;
  z-index: 11;
}

.logoGrid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 2rem;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
  position: relative;
  z-index: 11;
}

.logoGrid > div:nth-last-row(1) {
  grid-column: 2 / span 4;
}

.logoWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  background-color: white;
}

.logo {
  max-width: 150px;
  width: 100%;
  height: auto;
  filter: grayscale(100%);
  opacity: 0.7;
  transition: all 0.3s ease;
  display: block;
}

.logo:hover {
  filter: grayscale(0%);
  opacity: 1;
}

@media screen and (max-width: 768px) {
  .logoGrid {
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
    padding: 0 1rem;
  }

  .logo {
    max-width: 120px;
  }

  .trustedByContainer {
    padding: 1.5rem 0;
  }

  .logoWrapper {
    padding: 0.5rem;
  }
}

@media screen and (max-width: 480px) {
  .logoGrid {
    grid-template-columns: repeat(1, 1fr);
  }

  .logo {
    max-width: 100px;
  }
}
