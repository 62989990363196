.navDotsContainer {
  position: fixed;
  top: 168px;
  left: 5%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  z-index: 1000;
}

.dot {
  width: 17px;
  height: 17px;
  border-radius: 50%;
  background-color: black;
  cursor: pointer;
  border: none;
  padding: 0;
  transition: outline 0.2s ease;
}

.selected {
  background: var(--Gradient_Full, linear-gradient(90deg, #FF8C00 0%, #C4002F 50%, #D93DEA 100%));
}

/* Accessibility enhancements */
.dot:focus {
  outline: 2px solid #000;
  outline-offset: 2px;
}

.dot:focus:not(:focus-visible) {
  outline: none;
}

.dot:focus-visible {
  outline: 2px solid #000;
  outline-offset: 2px;
}

@media screen and (max-width: 768px) {
  .navDotsContainer {
    display: none;
  }
}