.gotQuestionsWere {
  margin: 0;
  flex: 1;
  position: relative;
  font-size: inherit;
  font-weight: 700;
  font-family: inherit;
  z-index: 1;
}

.questionTitle {
  width: 64.688rem;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0 var(--padding-102xl) 0 var(--padding-101xl);
  box-sizing: border-box;
  max-width: 100%;
  flex-shrink: 0;
}

.whatsNextOn {
  align-self: stretch;
  position: relative;
}

.labkickIcon {
  width: 0.875rem;
  height: 1.313rem;
  position: relative;
  object-fit: cover;
}

.labkickIconWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-7xs) 0 0;
}

.wereWorkingHard {
  flex: 1;
  position: relative;
  line-height: 1.563rem;
  display: inline-block;
  min-width: 15.688rem;
  max-width: 100%;
}

.frameParent,
.whatsNextOnYourFeatureRoParent {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}

.frameParent {
  align-self: stretch;
  flex-direction: row;
  gap: var(--gap-6xl);
  font-size: var(--p1-size);
  font-family: var(--p2);
}

.whatsNextOnYourFeatureRoParent {
  width: 26.563rem;
  margin: 0 !important;
  position: relative;
  flex-direction: column;
  gap: var(--gap-4xl);
}

.labkickIcon1 {
  height: 1.313rem;
  width: 0.875rem;
  position: relative;
  object-fit: cover;
}

.adminlabkickco {
  text-decoration: underline;
}

.canIMakeNewFeatureSuggestParent {
  width: 26.563rem;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-mini);
  max-width: 100%;
}

.labkickIconContainer {
  display: flex;
  flex-direction: column;
  padding: var(--padding-9xs) 0 0;
}

.faq,
.labkickIconContainer,
.whatDoIDoIfIFindASoftwParent {
  align-items: flex-start;
  justify-content: flex-start;
}

.question {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: var(--gap-base);
  max-width: 100%;
}

.faq {
  align-self: stretch;
  height: 50.625rem;
  border-radius: var(--br-xl);
  background-color: var(--color);
  border: 2px solid var(--pink);
  box-sizing: border-box;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
  padding: var(--padding-44xl) var(--padding-41xl);
  position: relative;
  gap: 3.062rem 2.75rem;
  z-index: 1;
  text-align: left;
  font-size: var(--h4-size);
}

.benefitItem,
.faq,
.questions {
  display: flex;
  max-width: 100%;
  z-index: 10;
}

.faq {
  justify-content: center;
  height: fit-content;
}

.questions {
  width: 66.125rem;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  gap: var(--gap-46xl);
}

.benefitItem {
  align-self: stretch;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 0 var(--padding-23xl) var(--padding-65xl) var(--padding-xl);
  box-sizing: border-box;
  text-align: center;
  font-size: var(--h2-size);
  color: var(--black);
  font-family: var(--h2);
  margin-top: 100px;
}

@media screen and (max-width: 1200px) {
  .questionTitle {
    padding-left: var(--padding-41xl);
    padding-right: var(--padding-41xl);
    box-sizing: border-box;
  }

  .questions {
    gap: var(--gap-13xl);
  }

  .benefitItem {
    padding-right: var(--padding-2xl);
    padding-bottom: var(--padding-36xl);
    box-sizing: border-box;
  }
}

@media screen and (max-width: 825px) {
  .gotQuestionsWere {
    font-size: var(--font-size-16xl);
  }

  .questionTitle {
    padding-left: var(--padding-11xl);
    padding-right: var(--padding-11xl);
    box-sizing: border-box;
  }

  .frameParent {
    flex-wrap: wrap;
  }

  .faq {
    height: auto;
    min-height: 810;
  }

  .questions {
    gap: var(--gap-base);
  }

  .benefitItem {
    padding-bottom: var(--padding-17xl);
    box-sizing: border-box;
  }
}

@media screen and (max-width: 450px) {
  .gotQuestionsWere {
    font-size: var(--font-size-7xl);
  }

  .whatsNextOn {
    font-size: var(--font-size-lgi);
  }

  .faq {
    padding: 20px;
  }
}

.faq a {
  color: var(--pink);
  text-decoration: underline;
  transition: all 0.2s ease-in-out;
}

.faq a:hover {
  opacity: 0.8;
}

.answer a {
  color: var(--pink);
  text-decoration: underline;
  transition: all 0.2s ease-in-out;
}

.answer a:hover {
  opacity: 0.8;
}

/* Add focus styles */
.faq:focus-within {
  outline: 2px solid var(--pink);
  outline-offset: 2px;
}

/* Update link styles to ensure sufficient contrast */
.faq a:focus,
.answer a:focus {
  outline: 2px solid var(--pink);
  outline-offset: 2px;
  text-decoration: underline;
  text-decoration-thickness: 2px;
}

/* Ensure focus visibility */
:focus {
  outline: 2px solid var(--pink);
  outline-offset: 2px;
}

/* Ensure interactive elements have visible focus */
[role="button"]:focus,
button:focus,
a:focus {
  outline: 2px solid var(--pink);
  outline-offset: 2px;
}